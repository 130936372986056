import * as React from 'react';
import Header from '../../layouts/header';
import Footer from '../../layouts/footer';
import '../../styles/_bootstrap.scss'
import '../index.scss';

class Thunderbolt extends React.PureComponent<{}, {}> {

    public render() {
        return (
            <>
                <Header />
                <div className="page">
                    <div className="container-fluid hero">
                        <section className="section hero-medium background-gradient">
                            <div className="hero-no-image padding-top-XL padding-bottom-M container">
                                <h1 className="text-left">Thunderbolt</h1>
                                <h4 className="text-left" style={{maxWidth: 75 + "%"}}>Thunderbolt is a e-commerce integration platform that allows you to connect with vendors, channels and customers</h4> 
                            </div>
                        </section>


                        <div className="container-fluid padding-none body">
                            <section className="section open">
                                <div className="col-md-8 col-md-offset-2 col-sm-12 margin-bottom-M intro">
                                    <p className="text-L padding-bottom-M">Thunderbolt Descript Text goes here I should have just used ipsum but I didnt so long sentence describing something needs to be written in its place what is actually said doesn't matter plattypus ellipses</p>
                                </div>
                                <div className="container padding-none">
                                    
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        )
    }
}

export default Thunderbolt; 